.controlled-toggle::after {
  display: none !important;
}

/* This is to change the default styling used in NavLink react-router */
.nav-link.active.active-primary {
  color: var(--primary) !important;
}

/* This is to change the default styling used in NavLink react-router */
.nav-link.active {
  color: var(--primary) !important;
}
