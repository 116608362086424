/* Import google fonts css*/
@import url("../googleFonts/googleFonts.css");

html {
  height: 100% !important;
}

#menu {
  display: none;
  position: absolute;
  width: 110px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
  z-index: 99;
}

#menu button {
  width: 100%;
  background-color: white;
  border: none;
  margin: 0;
  padding: 10px;
}

#menu button:hover {
  background-color: lightgray;
}

.disable-pointer {
  pointer-events: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: -moz-none;
}

.spinner {
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation: fade-in 0.4s ease-out;
  background-color: #000000;
  opacity: 0.9;
}
.spinner__container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 200px;
  height: 200px;
}
.op-20,
.op-20-hover:hover {
  opacity: 0.2 !important;
}
.op-40,
.op-40-hover:hover {
  opacity: 0.4 !important;
}
.op-50,
.op-50-hover:hover {
  opacity: 0.5 !important;
}
.op-90,
.op-90-hover:hover {
  opacity: 0.9 !important;
}

.img-box {
  height: 220px;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.img-box img.card-img-top.o-100-hover {
  top: 50% !important;
  bottom: 50% !important;
  transform: translate(0, -50%);
}

.body-container {
  position: relative;
  overflow: hidden;
}
.app-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.dropdown-area button {
  font-size: 12px;
  padding: 4px 8px !important;
  text-align: left;
}
.menu-box {
  min-width: 158px;
  border-radius: 0.25rem;
}

.Toastify__toast--default {
  background: transparent !important;
}
.Toastify__toast-container {
  width: unset !important;
}
.Toastify__toast-container .toast-header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.Toastify__toast-container .toast-header strong {
  width: 195px !important;
}
.Toastify__toast-container .toast-body > i {
  margin-right: 10px !important;
}
.Toastify__toast {
  padding: 0px !important;
  box-shadow: none !important;
}

/** Dropzone file uploader */

.dropzone-area {
  transition: background-color 0.2s linear;
  background-color: #ffd9ec;
  /*pointer-events: none;*/
}
.dropzone-area.dropzone-reveal {
  transition: opacity 0.1s linear;
  opacity: 0;
}
.dropzone-area.dropzone-reveal.ui-droppable-active {
  opacity: 1;
}
.dropzone-area.dropzone-transparent.ui-droppable-active {
  background-color: rgba(255, 168, 212, 0.75);
}
.dropzone-area.dropzone-transparent.ui-droppable-active.ui-droppable-hover {
  background-color: rgba(255, 168, 212, 0.9);
}
.dropzone-area.ui-droppable-active.ui-droppable-hover {
  background-color: #ffd9ec;
}
.dropzone-area.ui-droppable-active.ui-droppable-hover .dropzone-inner {
  border: solid 1px #f2318a;
}
.dropzone-area .dropzone-inner {
  transition: border 0.2s linear;
  border: dashed 1px #f2318a;
}

.rec-item-wrapper:not(:nth-child(1)) .border-dark.position-relative {
  width: 100%;
}

.border-dark.position-relative.overflow-hidden:focus {
  outline: none;
}

.border-dark.position-relative.stage-selected {
  border: 1px solid #f2318a !important;
  overflow: hidden;
}
.rec-item-wrapper {
  padding: 0px !important;
}
.border-dark.position-relative.over {
  border: 1px solid transparent !important;
}
div#v-pills-tabContent1 {
  margin-bottom: -3px;
}
.rec-item-wrapper {
  justify-content: start !important;
}
.rec-item-wrapper .border-dark.position-relative {
  background: #636a71 !important;
  border: 1px solid #343a40;
}
.rec-item-wrapper {
  height: 100%;
}
.rec.rec-slider-container,
.rec.rec-carousel,
.rec.rec-slider {
  height: 100% !important;
}
.btn-toolbar .dropdown-item {
  font-size: 0.875em;
}

.o-20 {
  opacity: 20% !important;
}

.o-20-hover:hover {
  opacity: 20% !important;
}
/*For stage loader*/

.loader-bg {
  transition: border 0.2s linear;
  background-color: rgba(80, 86, 90, 0.5);
  z-index: 999;
  left: 0;
  color: rgba(255, 255, 255, 0.4);
}

/* 404 page*/

.error {
  color: #5a5c69;
  font-size: 10rem;
  position: relative;
  line-height: 1;
}
.error-right {
  width: 447px;
}
.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}
.error-right p {
  font-size: 14px;
}
.error-right i {
  color: #f2318a !important;
  font-size: 33px;
}

/* warning icon in aoi error*/
.custom-warning {
  font-size: 0.875em;
  color: #ffc107;
}

.custom-warning-icon {
  height: 28px;
  width: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-tooltip {
  max-width: 200px;
  padding: 8px !important;
}

.custom-tooltip b {
  font-size: 0.81rem;
}

.btn-toolbar.custom-toolbar .dropdown li button.active .text-muted {
  color: #f5f5f5 !important;
}

.custom-error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

/** Export modal report list classes **/

.custom-input {
  min-width: 16px;
  margin-left: -21px !important;
}

.form-check.d-flex.align-items-center.mb-3 {
  min-height: 43px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.disable-stage-options {
  user-select: none;
  opacity: 0.25;
  pointer-events: none;
}

.rec-slider-container {
  margin: 0 !important;
}

.btn.btn-no-outline:focus {
  box-shadow: none !important;
  outline: none !important;
}
.dragging {
  cursor: grabbing;
  cursor: -webkit-gragging;
  cursor: -moz-gragging;
}

.start-40 {
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
}
.react-autosuggest__container {
  position: relative;
}

.btn-active {
  color: #f2318a;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px 10px 40px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: calc(100% + 46px);
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.inputContainer {
  position: relative;
}

.icon {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 24px;
  height: 24px;
}

.div-overlay:after {
  width: 1100000px;
  height: 100000px;
  content: "";
  position: fixed;
  bottom: 100%;
  left: -1000px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate(-50%, 50%);
}
.label-validate.form-control,
.react-autosuggest__container {
  z-index: 9;
  position: relative;
}
.div-overlay .inputContainer {
  position: relative;
  max-width: 260px;
  min-width: 120px;
}
.div-overlay input[type="search"] {
  padding-right: 51px !important;
  box-sizing: border-box;
}
.div-overlay .input-group-append {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 99;
}

.m-point-5 {
  margin: 0.125rem 0.05rem !important;
}

.manage-access-project-modal .lpl,
.excel-export .lpl,
.export-modal .lpl,
.new-project .lpl {
  padding-left: 4.5rem;
}
.manage-access-project-modal .label-icon,
.excel-export .label-icon,
.export-modal .label-icon,
.new-project .label-icon {
  width: 2.75rem;
  height: 2.75rem;
  margin: 0 0 0 1rem;
}

.media-modal .full-width {
  height: 100%;
  width: 100%;
}

.media-modal .bg-color {
  background: #f5f5f5;
}
.media-modal .zindex1 {
  z-index: 1;
}
.media-modal .dp-inner-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-modal .progress-body,
.excel-export .progress-body,
.export-modal .progress-body {
  background-position: bottom center;
  background-size: cover;
}
.media-modal .progress-height,
.excel-export .progress-height,
.export-modal .progress-height {
  height: 8px;
}
.media-modal .progress-width,
.excel-export .progress-width,
.export-modal .progress-width {
  max-width: 600px;
}
.media-modal .progress-circle,
.excel-export .progress-circle,
.export-modal .progress-circle,
#stageE_canvasWrapper .progress-circle,
.icon-size4 {
  width: 4rem;
  height: 4rem;
}

.media-modal .dpzone-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.excel-export .emd-body,
.export-modal .emd-body,
.new-project .emd-body {
  background-image: url("../img/background-wave.svg");
  background-position: bottom center;
  background-size: cover;
}

.excel-export .cardbg,
.cardbg {
  background-color: rgba(166, 213, 255, 0.2);
}
.excel-export .warningbg {
  background-color: rgba(255, 216, 166, 0.2);
}
.move-modal .i-max-width,
.change-owner .i-max-width,
.max-width400 {
  max-width: 400px !important;
}
#stageE_canvasWrapper .p-max-width {
  max-width: 200px;
}
.max-width375 {
  max-width: 375px;
}
.move-modal .down-list {
  max-height: 370px;
  overflow: hidden auto;
}
.move-modal .down-list-icon,
.change-owner .down-list-icon {
  width: 2.75rem;
  height: 2.75rem;
  margin: 0.75rem 0 0 0.75rem;
}
.move-modal .down-list-item,
.change-owner .down-list-item {
  padding: 1rem 1rem 1rem 4.25rem;
}
.move-modal .down-list-text,
.change-owner .down-list-text {
  padding: 1px 5px;
}
.new-project .folder-icon,
.loader .folder-icon,
.folder-icon-5r {
  height: 5rem;
  width: 5rem;
}

.max-width20 {
  max-width: 20em;
}

.max-width160 {
  max-width: 160px;
}
.saving {
  align-self: center;
  padding-right: 10px;
}
.editor-container.expand-container {
  padding-left: 100px;
}
.editor-container {
  padding-left: 240px;
  will-change: padding-left;
  transition: padding-left 500ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.report-div-height {
  height: calc(100% - 105px);
}
.carousel-div-height {
  height: calc(100% - 48px);
}
.loader-bg-color {
  background-color: #b5b5b5;
}
.pt-30 {
  padding-top: 30px;
}
.pt-58 {
  padding-top: 58px;
}
.pt-208 {
  padding-top: 208px;
}
.pt-182 {
  padding-top: 182px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-160 {
  padding-top: 160px;
}
.pt-190 {
  padding-top: 190px;
}
.pl-63 {
  padding-left: 63px;
}
.canvas-width {
  width: 120px;
}
.canvas-size {
  width: 85px;
  height: 40px;
}
.spinner-color {
  color: black;
}
.mw-20 {
  max-width: 20em;
}
.mw-36 {
  max-width: 36em;
}
.mh-49 {
  min-height: 49px;
}
.h-39 {
  height: 39px;
}
.icon-size {
  width: 2.75rem;
  height: 2.75rem;
}
.icon-size5 {
  width: 5rem;
  height: 5rem;
}
.pl-4r {
  padding-left: 4.25rem !important;
}
.icon-size-margin {
  margin: 0.75rem 0 0 0.75rem;
}
.folder-icon-size {
  width: 6rem;
  height: 6rem;
}
.iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}
.zindex1000 {
  z-index: 1000;
}
.zindex2 {
  z-index: 2;
}
.borderlc {
  border-left-color: rgba(0, 0, 0, 0.25);
}

.w-110 {
  width: 110px !important;
}
.collapse-media.w-240 {
  width: 100px !important;
}
.w-240 {
  width: 240px;
  will-change: width;
  transition: width 500ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.w-280 {
  width: 280px;
}
.w-32 {
  width: 32px;
}
.w-40 {
  width: 40px;
}
.h-38 {
  height: 38px;
}
.h-154 {
  height: 154px;
}
.w-15r {
  width: 1.5rem;
}
.wh-32 {
  width: 32px;
  height: 32px;
}
.w-125r {
  width: 1.25rem;
}
.w-25r {
  width: 2.5rem;
}
.w-3r {
  width: 3rem;
}
.w-4r {
  width: 4rem;
}
.w-225r {
  width: 2.25rem;
}

.w-110 {
  width: 110px;
}
.min-height {
  min-height: 100%;
}

.t-160 {
  top: 160px;
}
.t-1 {
  top: 1px;
}
.sidebar-body {
  height: calc(100% - 122px);
  overflow-y: scroll;
}
.sidebar-body .card-inner {
  padding-top: 0;
  padding-left: 0;
  background-color: transparent;
  border: none;
}
.collapse-media .sidebar-body .card-inner-text {
  width: auto;
  font-size: 0.625rem !important;
}
.sidebar-body .card-inner-text {
  width: 29px;
  z-index: 2;
  top: -0.5rem;
  left: -0.5rem;
}
.sidebar-body .card-inner-text-right {
  width: 29px;
  z-index: 2;
  top: -0.5rem;
  right: -0.5rem;
}

.collapse-media .text {
  display: none;
}
.collapse-media .collapse-media-btn {
  left: 88px;
  top: 29px;
}
.collapse-media-btn {
  position: absolute;
  left: 228px;
  top: 29px;
  will-change: left;
  transition: left 500ms cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.col-media-btn:hover {
  color: #fff;
  background-color: #f2318a;
  border-radius: 50%;
}
.img-outer-box {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-clip: border-box;
}
.collapse-media .img-box-size {
  height: 50px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.img-box-size {
  height: 100px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.spinner-size {
  width: 66px;
  padding: 20px 0;
}
.del-icon {
  top: -8px;
  right: -8px;
}
.hide-element {
  visibility: hidden;
}
.cus-list {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.list-group-flush .list-group-item {
  border-width: 0 !important;
}
.cus-list .summary {
  min-height: 37px;
  display: flex;
  align-items: center;
}
.li-title {
  min-width: 90px;
  color: #999;
}
.list-group-flush > .list-group-item:first-child {
  border-top-width: 0 !important;
}

.accordion-body.tb-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem 0.5rem 1rem;
}

div#accordionFlushExample small {
  font-style: normal;
  line-height: 29px;
}
.tb-content table {
  min-width: 200px;
  margin-left: 2.5rem;
}
.tb-content table th {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #98a1a9;
}
.tb-content table td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #353a3f;
}
.tb-content table td:not(:first-child),
.tb-content table th:not(:first-child) {
  text-align: center;
}
#accordionFlushExample .accordion-button:after {
  width: 13px;
  height: 13px;
  background-size: 13px !important;
}
.tb-status {
  border-radius: 4px;
  width: 32px;
  margin: auto;
  height: 25px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#accordionFlushExample .text-end small {
  line-height: 29px;
  letter-spacing: -0.05em;
  color: #353a3f;
}
div#accordionFlushExample .list-group-item {
  border-bottom: none;
  padding: 10px;
}
div#accordionFlushExample .accordion-body.tb-content {
  padding-top: 0;
}
div#accordionFlushExample span.w-15r {
  min-width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
}
.tb-status-red {
  background: rgba(255, 45, 45, 0.08) !important;
  color: #ff2d2d !important;
}
.tb-status-green {
  background: rgba(82, 203, 52, 0.12) !important;
  color: #52cb34 !important;
}
#accordionFlushExample .accordion-button:focus {
  box-shadow: none !important;
}
.areainterest-scroll {
  height: calc(100vh - 440px);
  overflow: auto;
}

.pro-scroll {
  height: calc(100vh - 440px);
  overflow: auto;
}

.editicon {
  width: 34px;
  height: 34px;
  background: #e6408d;
  border-radius: 3px 0px 0px 3px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ffffff;
  min-width: 34px;
}

.drop-btn button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.nadded .bgbox,
.nadded .bgbox small {
  background: #98a1a9;
  border: none !important;
  color: #ffffff;
  min-height: 24px;
  line-height: 32px;
  font-size: 16px;
}

.nadded small {
  font-size: 16px;
}

.upload-assign-info {
  filter: drop-shadow(-4px 4px 50px rgba(0, 0, 0, 0.08));
  z-index: 1045;
  width: 490px;
  margin: 60px 30px 0 0;
}

.text-gray {
  color: rgba(152, 161, 169, 0.5) !important;
}
.mw-155 {
  max-width: 155px !important;
}
.mw-120 {
  max-width: 120px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.list-group-item.accordion-header.btn {
  padding: 0.5rem;
}
.aoi-block-width {
  width: 150px;
}

.disabled-checkbox {
  background-color: #dee2e6 !important;
}
.context-tab-header {
  width: 2.4em;
  height: 2.4em;
  padding-top: 8px;
}
.shadow-primary {
  box-shadow: 0 0.5rem 1rem rgba(242, 49, 138, 0.15) !important;
}
.bg-info-10 {
  background-color: rgba(6, 78, 142, 0.1) !important;
}
.text-info {
  color: #064e8e !important;
}
.swiper.swiper-initialized {
  width: 100%;
}

.bg-darken-30 {
  background-color: rgba(0,0,0,.3) !important;
}

.usecase-list {
  max-height: calc(100vh - 350px);
  overflow: hidden auto;
  min-height: 160px;
}

@media (min-width: 576px) {
  .stage-header {
    max-height: calc(100vh - 470px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media (min-width: 768px) {
  .stage-header {
    max-height: calc(100vh - 320px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 992px) {
  .stage-header {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 1200px) {
  .stage-header {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

button:disabled {
  opacity: 0.2 !important;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.disabled-dropdown {
  opacity: 0.2 !important;
}

/* a.active {
  color: var(--bs-primary) !important;
} */

/* This is due to a right auto !important on dropdown-menu-end[style] */
#menu-end-fix[style] {
  --bs-position: end;
  right: 0 !important;
  left: auto;
}