:root {
  --primary: #f2318a;
  --newWidth: 5.2rem;
}

.selected {
  border-color: var(--primary) !important;
  border-width: 1px !important;
  background-color: #f2318b10 !important;
}

.reports-card {
  position: relative;
  border-radius: 16px !important;
  cursor: pointer;
}

.reports-card:hover {
  transition: border 0.1s ease-in-out;
  border-color: var(--primary);
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed !important;
}

/* .disabled:hover {
  border-color: rgba(0, 0, 0, 0.125) !important;
} */

.new-feature {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 10%;
  background-color: var(--primary);
  transform-origin: top right;
  /* Offset the rotation to keep the absolute positioning correct */
  transform: rotate(-90deg) translateY(calc(var(--newWidth) * -1 - 1px));
  color: white;
  padding: 0.2rem;
  /* Width must be the same as the translateY */
  width: var(--newWidth);
  border-radius: 0 0 12px 12px;
  font-weight: bolder;
  letter-spacing: 0.1rem;
}

.col-separator {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  padding-left: 1.5rem !important;
}

/* Empty state of the 'tickbox' */
.report-tick-empty {
  position: absolute;
  right: 0;
  top: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 10px;
}

.report-tick {
  display: none;
}

/* shows the fontawesome tick when selected */
.selected > .report-tick {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--primary);
}
